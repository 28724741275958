<template>
  <details 
    class="nft-vouchers"
    open>
    <summary class="nft-vouchers__summary">{{ $t('user-portal.nft_vouchers') }}</summary>

    <div class="nft-vouchers__body">
      <div class="nft-vouchers__list">
        <card-voucher
          :children="voucher.generated"
          :generation="voucher"
          :interactive="false"
          is-template
          :key="voucher.id"
          :voucher="voucher.template"
          v-for="voucher in list"
        />
      </div>
    </div>
  </details>
</template>

<script>
import CardVoucher from '../ui/card-voucher.vue'

export default {
  name: 'NftVouchers',

  components: {
    CardVoucher
  },

  props: {
    generated: {},
    vouchers: {},
  },

  computed: {
    list() {
      let ret = this.vouchers || []
      let used = []
      
      let rett = []
      ret.forEach((v) => {
        if(this.generated[v.id]) {
          v.generated = this.generated[v.id]
        }
        rett.push(v)
        used.push(v.id)
      })

      Object.keys(this.generated).forEach(v => {
        if(!used.includes(v)) {
          let vv = this.generated[v][0].copy()
          
          vv.generated = this.generated[v]
          vv.template = this.generated[v][0]
          vv.isTemplate = true

          rett.push(vv)
        }
      })

      return rett
    },
  },
}
</script>